import './index.scss'
import request from '../../utils/request'
import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import Discount from '../../components/discount'
import PublicBottom from '../../components/publicBottom'
import PublicTabber from '../../components/publicTabber'
import { Pagination,ConfigProvider } from 'antd'
export default function ChargingStandard(props) {
	let [list,setList] = useState([
		{Q:'没有我需要的模板？',A:'可以通过自行配置组件的方式设计用户端'},
		{Q:'支持哪些类型的用户端？',A:'目前支持的是微信小程序与H5用户端，微信小程序支持个人主体和商户主体，自动完成微信小程序的代注册功能，可免微信300元认证费用'},
		{Q:'体验模板需要付费吗？',A:'不需要'},
	])
	let [tagList,settagList] = useState([])
	let [totalPages,settotalPages] = useState(0)
	let [componentList,setcomponentList] = useState([])
	let [page,setPage] = useState(1)
	let [size,setSize] = useState(6)
	useEffect(()=>{
		window.scrollTo(0, 0);
		GetIndustryList()
		GetList()
	},[])
	// 查询筛选行业列表
	let GetIndustryList = () => {
		request({
			url: '/component/api/web/appTemplate/findIndustryList',
			method: 'POST',
			data: {
			},
		}).then(res=>{
			console.log(res)
			let { data, errorCode } = res.data
			if (errorCode == 0) {
				let resultTagData = data.map(i => {
					i.selectFlag = false
					return i
				})
				resultTagData.unshift({
					name: '全部',
					selectFlag: true
				})
				settagList(resultTagData)
			}
		})
	}
	// 标签的点击
	let TagClick = (tarVal) => {
		let resultTagData = tagList.map(i => {
			if (i.name == tarVal.name) {
				i.selectFlag = true
			} else if (i.selectFlag) {
				i.selectFlag = false
			}
			return i
		})
		settagList(resultTagData)
		setPage(1)
		GetList(tarVal,1)
	}
	//获取模板列表
	let GetList = (tarVal = undefined,pages) => {
		request({
			url: '/component/api/web/appTemplate/findListByPage',
			method: 'POST',
			data: {
				page:pages||page,
				size,
				platformId: 1,
				industryId: tarVal == undefined ? undefined : tarVal.id
			},
		}).then(res=>{
			const { data } = res.data
			setcomponentList(data.list)
			settotalPages(data.total)
		})
	}
	return (
		<div className="chargingStandard">
			<PublicTabber type='1' path='productservice' />
			<div className='bannerBox'>
					<div className='banner'>
						<img src={require('./img/gjjghjhgjghutyu.png')} alt="" />
					<div className='content'>
						<div className='title'>
						模板中心
						</div>
						<div className='text'>
							<img src={require('./img/SketchPngd4ea10ca06234fe12a04de3975e3bdcc1cd1f0adb60816c19f051cb6bd61b645.png')} />
							小程序模板、微商城模板，快速拥有自己专属小程序
						</div>
					</div>
				</div>
			</div>
			<div className='templatebox'>
				<div className='templatetitle'>
					模板列表
					<span>共{totalPages}个模板</span>
				</div>
				<div className='templatetab'>
					{tagList.map(c=>{
						return (
							<div onClick={()=>TagClick(c)} className={`templatetabitem ${c.selectFlag?'changeitem':''}`}>{c.name}</div>
						)
					})}
				</div>
				<div className='templatelist'>
					{componentList.map(c=>{
						return (<div className='templatelistitem'><img src={c.coverImage} /> </div>)
					})}
				</div>
			</div>
			
			<div className='paginationBox'>
				<div className='pagination'>
					<Pagination current={page} pageSize={size} onChange={(page,pageSize)=>{
						setPage(page)
						setSize(pageSize)
						GetList('',page)
					}} pageSizeOptions={['6','10','20']} showSizeChanger total={totalPages} />
				</div>
			</div>
			<div className='QA'>
				<div className='title'>模板 Q&A</div>
				<div className='main'>
						{list.map(c=>{
							return (
								<div className='mainitem'>
									<div><span>Q</span>{c.Q}</div>
									<div><span>A</span>{c.A}</div>
								</div>
							)
						})}
				</div>
			</div>
			
			<Discount />
			<PublicBottom />
		</div>
	)
}