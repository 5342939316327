import 'lib-flexible'
import React from 'react'
import App from './view/App/index'
import { createRoot } from 'react-dom/client'
import { HashRouter } from 'react-router-dom'
import { ConfigProvider } from 'antd'
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import zhCN from 'antd/locale/zh_CN';
import 'antd/dist/reset.css';
import './icon/iconfont.css'
const root = createRoot(document.getElementById('root'))
root.render(
	<ConfigProvider locale={zhCN}>
		<HashRouter>
			<App />
		</HashRouter>
	</ConfigProvider>
	
)
