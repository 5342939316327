import './index.scss'
import request from '../../utils/request'
import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import Discount from '../../components/discount'
import PublicBottom from '../../components/publicBottom'
import PublicTabber from '../../components/publicTabber'
import Miniappmodel from '../../components/miniappmodel'
import Customermodel from '../../components/customermodel'
export default function ChargingStandard(props) {
	let [list,setList] = useState([
		{Q:'免费用户的无账号数量限制具体是指？免费用户是否可以申请独立版小程序？',A:'可以申请多个小程序，不限制员工绑定数量/可以申请但是无法关联主体'},
		{Q:'免费用户公版首年免费，那次年呢，到期后无法使用还是引导？计算起止时间怎么计算？',A:'小程序关联主体时间开始计算开始时间，到期后引导购买基础版'},
		{Q:'基础版、高级版是否还有月、季服务周期？',A:'存在'},
		{Q:'免费用户申请公共版后可以关联主体运营？',A:'产品范围中显示2个公共版，可以申请N个公共版小程序，但有2个可以关联主体'},
		{Q:'如何开发票？',A:'联系我们的客服'},
		{Q:'订单在哪里可以看到？',A:'点击产品中心——我的——我的订单即可查询'},
	])
	useEffect(()=>{
		window.scrollTo(0, 0);
	},[])
	let [flag,setFlag] = useState(false)
	let [miniFlag,setMiniFlag ] = useState(false)
	return (
		<div className="chargingStandard">
			<PublicTabber type='1' path='ChargingStandard' />
			<div className='bannerBox'>
					<div className='banner'>
						<img src={require('./img/gjjghjhgjghutyu.png')} alt="" />
					<div className='content'>
						<div className='title'>
						收费标准
						</div>
						<div className='text'>
							<img src={require('./img/SketchPngd4ea10ca06234fe12a04de3975e3bdcc1cd1f0adb60816c19f051cb6bd61b645.png')} />
							免费用户试用畅想、普通会员全场优选、高级会员个性开发
						</div>
					</div>
				</div>
			</div>
			<div className='typelistBox'>
				<div className='typelist'>
					<div className='typelistitem'>
						<div className='title'>免费版</div>
						<div className='price'>
							<span>￥</span>0 
						</div>
						<div className='user'>体验用户</div>
						<p>
							<img src={require('./img/gou.png')} />
							免费使用所有的基础模板
						</p>
						<p>
							<img src={require('./img/gou.png')} />
							免费使用所有基础功能
						</p>
						<p>
							<img src={require('./img/cha.png')} />
							无法自定义组件
						</p>
						<div className='btn' onClick={()=>{
								setMiniFlag(true)
							}}>免费试用</div>
					</div>
					<div className='typelistitem'>
						<div className='title'>普通会员</div>
						<div className='price'>
							<span>￥</span>299.00<span> /年 </span>
						</div>
						<div className='user'>中小型商户</div>
						<p>
							<img src={require('./img/gou.png')} />
							支持公共版小程序
						</p>
						<p>
							<img src={require('./img/gou.png')} />
							支持独立版（个人主体）
						</p>
						<p>
							<img src={require('./img/gou.png')} />
							基础模板选择功能
						</p>
						<p>
							<img src={require('./img/gou.png')} />
							会员专属模板选择
						</p>
						<div className='btn' onClick={()=>{
								setMiniFlag(true)
							}}>立即购买</div>
					</div>
					<div className='typelistitem'>
						<div className='title'>高级会员</div>
						<div className='price'>
						<span>￥</span>999.00<span> /年 </span>
						</div>
						<div className='user'>大中小企业+商户</div>
						<p>
							<img src={require('./img/gou.png')} />
							支持公共版(无数量限制）
						</p>
						<p>
							<img src={require('./img/gou.png')} />
							支持独立版（个人+商户主体）
						</p>
						<p>
							<img src={require('./img/gou.png')} />
							支持自定义组件
						</p>
						<p>
							<img src={require('./img/gou.png')} />
							客服提供专属指导+专属模板
						</p>
						<div className='btn' onClick={()=>{
								setMiniFlag(true)
							}}>立即购买</div>
					</div>
					<div className='typelistitem'>
						<div className='title'>大客户定制</div>
						<img src={require('./img/fvgnfght6556.png')} />
						<div className='user'>定制开发专属</div>
						<p>
							<img src={require('./img/gou.png')} />
							私有功能
						</p>
						<p>
							<img src={require('./img/gou.png')} />
							定制开发
						</p>
						<p>
							<img src={require('./img/gou.png')} />
							专属客服
						</p>
						<div className='btn' onClick={()=>{
								setFlag(true)
							}}>联系我们</div>
					</div>
				</div>
			</div>
			<div className='pricescheme'>
				<div className='title'>价格方案比较</div>
				<img src={require('./img/avcbghkjfgfg.png')} />
			</div>
			<div className='QA'>
				<div className='title'>问题解答</div>
				<div className='main'>
						{list.map(c=>{
							return (
								<div className='mainitem'>
									<div><span>Q</span>{c.Q}</div>
									<div><span>A</span>{c.A}</div>
								</div>
							)
						})}
				</div>
			</div>
			<Discount />
			<PublicBottom />
			{miniFlag?<Miniappmodel close={()=>{
				setMiniFlag(false)
			}} /> :''}
			{flag?<Customermodel close={()=>{setFlag(false)}} />:''}
		</div>
	)
}