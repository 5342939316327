import React from 'react'
import routes from '../../route/index'
import { Routes, Route } from 'react-router-dom'

export default function App() {
	return (
		<Routes>
			{routes.map((item) => {
				return <Route key={item.path} path={item.path} element={<item.component id={item.id} />} />
			})}
		</Routes>
	)
}
