import Index from '../view/index/index'
import ProductEntrance from '../view/productEntrance/index'  //产品入口
import Aboutus from '../view/aboutus/index' //关于我们
import Systemfunction from '../view/systemfunction/index' //系统功能
import Customerplan from '../view/customerplan/index' //客计划
import ChargingStandard from '../view/chargingStandard/index' //收费标准
import Templatecenter from '../view/templatecenter/index' //模板中心
import Fastexperience from '../view/fastexperience/index' //快速体验
//页面级组件
let routes = [
	{
		path: '/',
		component: Index
	},
	{
		path: '/ProductEntrance',
		component: ProductEntrance
	},
	{
		path: '/Aboutus',
		component: Aboutus
	},
	{
		path: '/Customerplan',
		component: Customerplan
	},
	{
		path: '/Systemfunction',
		component: Systemfunction
	},
	{
		path: '/ChargingStandard',
		component: ChargingStandard
	},
	{
		path: '/Templatecenter',
		component: Templatecenter
	},
	{
		path: '/Fastexperience',
		component: Fastexperience
	}
]

export default routes
